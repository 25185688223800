import React from "react"
import { Link } from "gatsby"
import { Form } from '../../containers/MainWrapper'
import UserIcon from '../../assets/icons/user.svg';
import LockIcon from '../../assets/icons/lock-solid.svg';

export default ({ handleSubmit, handleUpdate }) => (
  <Form>
    <div className="summary">
      <h3>Votre espace personnel</h3>
      <span>Pas encore de compte ? <Link to="/app/inscription">Inscrivez-vous maintenant !</Link></span>
    </div>
    <form method="post" onSubmit={event => { handleSubmit(event) }}>
      <div className="input-text input-user-email">
        <span><UserIcon/></span>
        <input 
          type="email" 
          name="username" 
          onChange={handleUpdate}
          placeholder="Adresse email"
        />
      </div>

      <div className="input-text input-user-password">
        <span><LockIcon/></span>
        <input 
          type="password" 
          name="password" 
          onChange={handleUpdate}
          placeholder="Mot de passe"
        />
      </div>
      
      <Link to="/app/mot-de-passe-oublie">Mot de passe oublié ?</Link>

      <button className="submit-form signin-form">Connexion</button>
    </form>
  </Form>
)