import React from "react"
import axios from 'axios';
import { getCurrentUser } from "../../services/auth"
import { MainContainer, View, Row } from '../../containers/MainWrapper'
import { DashboardViewport } from "../../containers/Dashboard"
import Head from "./Head"
import Sidebar from "./Sidebar"
import Uploader from "../Upload"


class Dashboard extends React.Component {

  state = {
    loaded: false,
    user: null,
    data: null,
  }

  componentDidMount() {
    const _self = this;
    const user = getCurrentUser();
    axios.post(`${process.env.AFC_API_ENDPOINT}`, {
      action: 'get_user_profile',
      id: user.id,
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    }).then((result) => {
      if (Object.keys(result.data.user).length > 0) {
        _self.setState({ 
          user: user,
          data: result.data,
          loaded: true
        });
      }
    });
  }

  render() {
    
    return (
      <MainContainer style={{ background: '#f8f8f8' }} className={this.state.loaded ? 'loaded' : ''}>
        {
          this.state.data && (
            <React.Fragment>
              <Head page={this.props.page} />
              <View>
                <Row>
                  <div className="col-lg-3">
                    <Sidebar data={this.state.data} page={this.props.page} />
                  </div>

                  <div className="col-lg-9">
                    <DashboardViewport>
                      <div className="content">
                        <Row>
                          <div className="col-md-6 profile">
                            <div className="user-img">
                              <img 
                                id="profile-avatar" 
                                src={`${process.env.AFC_STORAGE}/profile/${this.state.data.user.avatar}`}
                                alt={this.state.data.user.name}
                                className="img-responsive"
                              />
                            </div>
                            <div>
                              <h2>{this.state.data.user.name}</h2>
                              <small>Dernière connexion: {this.state.data.user.last_activity}</small>
                              <Uploader {...this.state.user} />
                            </div>
                          </div>
                          <div className="col-md-6 text-right">
                            <span className="badges"><strong>0</strong> favoris</span>
                            <span className="badges">
                              <strong>{this.state.data.ads.collection.length}</strong> 
                              annonce{this.state.data.ads.collection.length > 1 ? 's' : ''}
                            </span>
                          </div>
                        </Row>
                      </div>
                    </DashboardViewport>

                    <DashboardViewport>
                      <form method="post">
                        <div className="headline">Informations personelles</div>
                        <Row>
                          <div className="col-md-12">
                            <div className="content">
                              <div className="fields">
                                <h5>Nom</h5>
                                <div className="inputs">
                                  <input type="text" name="name" id="name" defaultValue={this.state.data.user.name} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </form>
                    </DashboardViewport>
                  </div>

                </Row>
              </View>
            </React.Fragment>
          )
        }
      </MainContainer>
    )
  }
}

export default Dashboard