import React from "react"
import axios from 'axios';
import { Link, navigate } from "gatsby"
import { getCurrentUser } from "../../services/auth"
import { MainContainer, View, Row } from '../../containers/MainWrapper'
import Head from "./Head"
import Sidebar from "./Sidebar"
import { AdContainer, AdGridLayout, AdGridItem } from '../../containers/Ads'

import TextTruncate from 'react-text-truncate';
import CardIcon from '../../assets/icons/credit-card.svg';
import UserIcon from '../../assets/icons/user.svg';
import MarkerIcon from '../../assets/icons/map-marker-solid.svg';
import ClockIcon from '../../assets/icons/clock.svg';
import TagsIcon from '../../assets/icons/tags-solid.svg';
import { Edit, EyeOff, Eye, Trash } from 'react-feather';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class DashboardAds extends React.Component {

  state = {
    loaded: false,
    user: null,
    data: null,
  }

  componentDidMount() {
    const _self = this;
    const user = getCurrentUser();
    axios.post(`${process.env.AFC_API_ENDPOINT}`, {
      action: 'get_user_profile',
      id: user.id,
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    }).then((result) => {
      if (Object.keys(result.data.user).length > 0) {
        _self.setState({ 
          user: user,
          data: result.data,
          loaded: true
        });
      }
    });
  }

  handleEdit = (id) => {
    navigate(`/annonces/collection/${this.state.user.id}/${id}/modifier`);
  }

  handleHide = (id) => {
    const _self = this;
    const ads = this.state.data.ads.collection;
    const findAd = ads.findIndex(item => parseInt(item.id) === parseInt(id));

    axios.post(`${process.env.AFC_API_ENDPOINT}`, {
      action: 'hide_ad',
      user_id: this.state.user.id,
      id: id,
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    }).then((result) => {
      if (!result.data.error) {
        let newState = this.state;
        newState.data.ads.collection[findAd].hide = result.data.hide;
        _self.setState(newState);
      }
    });
  }

  handleRemove = (id) => {
    const _self = this;
    const ads = this.state.data.ads.collection;

    // axios.post(`${process.env.AFC_API_ENDPOINT}`, {
    //   action: 'remove_ad',
    //   user_id: this.state.user.id,
    //   id: id,
    // }, {
    //   headers: {
    //     "Content-Type": "application/json",
    //   }
    // }).then((result) => {
    //   if (!result.data.error) {
    //     let newState = this.state;
    //     newState.data.ads.collection[findAd].hide = result.data.hide;
    //     _self.setState(newState);
    //   }
    // });

    let newState = this.state;
    newState.data.ads.collection = ads.filter(item => parseInt(item.id) !== parseInt(id));;
    _self.setState(newState);
  }

  confirmRemove = (id) => {
    confirmAlert({
      title: 'Confirmation de suppression',
      message: `Êtes-vous certain(e) de vouloir supprimer cette annonce ?`,
      buttons: [
        {
          label: 'Oui',
          onClick: () => this.handleRemove(id)
        },
        {
          label: 'Non',
          onClick: () => false
        }
      ]
    });
  };

  render() {
    return (
      <MainContainer style={{ background: '#f8f8f8' }} className={this.state.loaded ? 'loaded' : ''}>
        {
          this.state.data && (
            <React.Fragment>
              <Head page={this.props.page} />
              <View>
                <Row>
                  <div className="col-lg-3">
                    <Sidebar data={this.state.data} page={this.props.page} />
                  </div>

                  <div className="col-lg-9">
                    <AdContainer>
                      <AdGridLayout className="full">
                        {
                          this.state.data.ads.collection.length > 0 && this.state.data.ads.collection.map((item, index) => {
                            return (
                              <AdGridItem key={`ad-item-${index}`} className="full">
                                <div className="details">
                                  <div className="hero">
                                    <Link to={`/annonces/${item.id}/${item.link}`}>
                                      <img 
                                        src={`${process.env.AFC_STORAGE}/products/thumb/${item.picture}`} 
                                        alt={item.product_name} 
                                      />
                                    </Link>
                                  </div>

                                  <div className="description">
                                    <h3>
                                      <Link to={`/annonces/${item.id}/${item.link}`}>
                                        {item.product_name}
                                      </Link>
                                    </h3>
                                    <TextTruncate
                                      line={2}
                                      element="p"
                                      truncateText="…"
                                      text={item.description}
                                    />
                                    <ol className="breadcrumb">
                                      <li>
                                        <Link to={`/annonces/categorie/${item.category_id}${item.catlink}`}>
                                          <TagsIcon /> {item.category}
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={`/annonces/categorie/${item.category_id}/${item.sub_category_id}${item.subcatlink}`}>
                                          {item.sub_category}
                                        </Link>
                                      </li>
                                    </ol>
                                    <h5>
                                      <Link to={`/`}><UserIcon /> {item.username}</Link>
                                    </h5>
                                  </div>
                                </div>

                                <div className="footer">
                                  <ul>
                                    <li>
                                      <Link to={`/annonces/lieu/${item.city}${item.citylink}`}>
                                        <MarkerIcon /> {item.cityname}
                                      </Link>
                                    </li>
                                    <li>
                                      <span>
                                        <CardIcon /> {item.price} €
                                      </span>
                                    </li>
                                    <li><span><ClockIcon /> {item.created_at}</span></li>
                                  </ul>

                                  <ul>
                                    <li>
                                      <button onClick={() => this.handleEdit(item.id)}>
                                        <Edit /> Modifier
                                      </button>
                                    </li>
                                    <li>
                                      <button onClick={() => this.handleHide(item.id)}>
                                        {item.hide ? <><EyeOff /> Afficher</> : <><Eye /> Masquer</>}
                                      </button>
                                    </li>
                                    <li>
                                      <button onClick={() => this.confirmRemove(item.id)}><Trash /> Supprimer</button>
                                    </li>
                                  </ul>
                                </div>
                              </AdGridItem>
                            )
                          })
                        }
                      </AdGridLayout>
                    </AdContainer>
                  </div>
                </Row>
              </View>
            </React.Fragment>
          )
        }
      </MainContainer>
    )
  }
}

export default DashboardAds