import React from "react"
import { Form } from '../../containers/MainWrapper'
import LockIcon from '../../assets/icons/lock-solid.svg';

export default ({ handleSubmit, handleUpdate }) => (
  <Form>
    <div className="summary">
      <h3>Créer un nouveau mot de passe</h3>
      <span>
        Saisissez votre nouveau mot de passe.
      </span>
    </div>
    <form method="post" onSubmit={event => { handleSubmit(event) }}>

      <div className="input-text input-user-email">
        <span><LockIcon/></span>
        <input 
          type="password" 
          name="password" 
          onChange={handleUpdate}
          placeholder="Mot de passe"
        />
      </div>

      <button className="submit-form new-password-form">Enregistrer</button>
    </form>
  </Form>
)