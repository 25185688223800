import React from "react"
import { Link, navigate } from "gatsby"
import { MainContainer, Baseline, View, Row } from '../containers/MainWrapper'
import { NewPasswordForm } from "./Form"
import { submitPassword } from "../services/auth"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class NewPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      password: ``,
    }
    this.opts = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: false,
      draggable: false,
      pauseOnHover: false,
    }
  }

  componentDidMount() {
    toast.dismiss();
    toast.configure(this.opts);
    this.setState({ loaded: true });
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()

    const _self = this;
    const button = document.querySelector('.new-password-form');
    button.classList.add('disabled');
    button.disabled = true;

    toast.dismiss();
    const loading = toast.loading("Traitement en cours...");
    const { id, token } = this.props;
    const response = await submitPassword({
      password: this.state.password,
      id: id,
      token: token
    });
    
    if (response.error) {
      setTimeout(() => {
        toast.dismiss();
        toast(response.error, { type: 'error', autoClose: false });
        button.classList.remove('disabled');
        button.disabled = false;
      }, 1000);
      return false;
    }
    
    setTimeout(() => {
      toast.update(loading, { 
        render: "Mise à jour de votre mot de passe réussie", 
        type: "success", 
        isLoading: false,
        ..._self.opts
      });
      setTimeout(() => {
        navigate(`/app/connexion`, { replace: true });
      }, 1000);
    }, 1000);
  }

  render() {
    
    return (
      <MainContainer className={this.state.loaded ? 'loaded' : ''}>
        <Baseline>
          <View>
            <h2>Mot de passe oublié</h2>
            <nav id="breadcrumb">
              <ul>
                <li><Link to="/annonces">Accueil</Link></li>
                <li>
                  <Link to={`/app/nouveau-mot-de-passe`}>Nouveau mot de passe</Link>
                </li>
              </ul>
            </nav>
          </View>
        </Baseline>

        <View>
          <Row>
            <div className="col-xl-5 centered">
              <NewPasswordForm
                handleUpdate={e => this.handleUpdate(e)}
                handleSubmit={e => this.handleSubmit(e)}
              />
            </div>
          </Row>
        </View>
      </MainContainer>
    )
  }
}

export default NewPassword