import React from 'react';
import ImageUploading from 'react-images-uploading';
import axios from 'axios';

const Uploader = (user) => {
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    axios.post(`${process.env.AFC_API_ENDPOINT}`, {
      action: 'upload',
      user: user,
      file_name: imageList[0].file.name,
      data_url: imageList[0].data_url,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(res => {
      console.log(res)
      document.getElementById('profile-avatar').setAttribute("src", imageList[0].data_url);
    });
    setImages(imageList);
  };
  
  return (
    <ImageUploading
      multiple={false}
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        <div className="upload__image-wrapper">
          <button
            style={isDragging ? { color: 'red' } : undefined}
            onClick={onImageUpload}
            {...dragProps}
          >
            Changer mon avatar
          </button>
          {/* &nbsp; */}
          {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
          {/* {imageList.map((image, index) => (
            <div key={index} className="image-item">
              <img src={image['data_url']} alt="" width="100" />
              <div className="image-item__btn-wrapper">
                <button onClick={() => onImageUpdate(index)}>Update</button>
                <button onClick={() => onImageRemove(index)}>Remove</button>
              </div>
            </div>
          ))} */}
        </div>
      )}
    </ImageUploading>
  );
}

export default Uploader;