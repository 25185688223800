import React from "react"
import { Link, navigate } from "gatsby"
import { logout } from "../../services/auth"
import { DashboardSidebar } from "../../containers/Dashboard"
import { 
  Grid,
  User,
  Briefcase,
  // Heart,
  // Clock,
  // EyeOff,
  // AlertOctagon,
  Settings,
  LogOut 
} from 'react-feather';

export default ({ data, page }) => {
  
  return (
    <DashboardSidebar>
      <nav>
        <ul data-submenu-title="Ma Classifié">
          <li className={page === 'dashboard' ? 'active' : ''}>
            <Grid />
            <Link to="/app/mon-compte"> Tableau de bord</Link>
          </li>
          <li>
            <User />
            <Link to={`/annonces/profil/${data.user.id}/${data.user.username}`}> Profil vue publique</Link>
          </li>
        </ul>

        <ul data-submenu-title="Mes annonces">
          <li className={page === 'ads' ? 'active' : ''}>
            <Briefcase />
            <Link to="/app/mon-compte/annonces"> Mes annonces <span className="nav-tag">{data.ads.collection.length}</span></Link>
          </li>
          {/* <li>
            <Heart />
            <Link to="/"> favori <span className="nav-tag">0</span></Link>
          </li>
          <li>
            <Clock />
            <Link to="/"> En attente <span className="nav-tag">0</span></Link>
          </li>
          <li>
            <EyeOff />
            <Link to="/"> Cachées <span className="nav-tag">0</span></Link>
          </li>
          <li>
            <AlertOctagon />
            <Link to="/"> Annonces terminées <span className="nav-tag">0</span></Link>
          </li> */}
        </ul>

        <ul data-submenu-title="Mon compte">
          <li className={page === 'settings' ? 'active' : ''}>
            <Settings />
            <Link to="/app/mon-compte/configuration"> Paramètres du compte</Link>
          </li>
          <li>
            <LogOut />
            <Link to="/app/connexion" onClick={event => {
              event.preventDefault()
              logout(() => navigate(`/app/connexion`))
            }}>
              Déconnexion
            </Link>
          </li>
        </ul>
      </nav>
    </DashboardSidebar>
  )
}
