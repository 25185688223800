import React from "react"
import { Link } from "gatsby"
import { Form } from '../../containers/MainWrapper'
import UserIcon from '../../assets/icons/user.svg';
import LockIcon from '../../assets/icons/lock-solid.svg';
import EmailIcon from '../../assets/icons/envelope.svg';

export default ({ handleSubmit, handleUpdate }) => (
  <Form>
    <div className="summary">
      <h3>Votre espace personnel</h3>
      <span>Vous avez déjà un compte ? <Link to="/app/connexion">S'identifier</Link></span>
    </div>
    <form method="post" onSubmit={event => { handleSubmit(event) }}>
      <div className="input-text input-user-name">
        <span><UserIcon/></span>
        <input 
          type="text" 
          name="name" 
          onChange={handleUpdate}
          placeholder="Nom et prénom"
        />
      </div>

      <div className="input-text input-user-email">
        <span><EmailIcon/></span>
        <input 
          type="email" 
          name="email" 
          onChange={handleUpdate}
          placeholder="Adresse email"
        />
      </div>

      <div className="input-text input-user-password">
        <span><LockIcon/></span>
        <input 
          type="password" 
          name="password" 
          onChange={handleUpdate}
          placeholder="Mot de passe"
        />
      </div>
      
      <p>En cliquant sur "Inscription", vous acceptez nos conditions d'utilisation.</p>

      <button className="submit-form signup-form">Inscription</button>
    </form>
  </Form>
)