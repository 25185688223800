import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../components/PrivateRoute"
import Dashboard from "../components/Dashboard"
import DashboardAds from "../components/Dashboard/Ads"
import DashboardSettings from "../components/Dashboard/Settings"
import Login from "../components/Login"
import Signup from "../components/Signup"
import RetrievePassword from "../components/RetrievePassword"
import NewPassword from "../components/NewPassword"

import MainWrapper from '../containers/MainWrapper'
import Header from '../components/Header'
import SEO from '../components/SEO'

const App = ({
  pageContext: { slug },
  location
}) => (
  <React.Fragment>
    <SEO />
    <Header slug={slug} location={location} />
    <MainWrapper>
      <Router>
        <PrivateRoute path="/app/mon-compte" component={Dashboard} page="dashboard" />
        <PrivateRoute path="/app/mon-compte/annonces" component={DashboardAds} page="ads" />
        <PrivateRoute path="/app/mon-compte/configuration" component={DashboardSettings} page="settings" />
        <Login path="/app/connexion" />
        <Signup path="/app/inscription" />
        <RetrievePassword path="/app/mot-de-passe-oublie" />
        <NewPassword path="/app/nouveau-mot-de-passe/:id/:token" />
      </Router>
    </MainWrapper>
  </React.Fragment>
)
export default App