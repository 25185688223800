import React from "react"
import axios from 'axios';
import { Link } from "gatsby"
import { MainContainer, Baseline, View, Row } from '../containers/MainWrapper'
import { LostPassword } from "./Form"
import { checkEmail } from "../services/auth"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class RetrievePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: ``,
    }
    this.opts = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: false,
      draggable: false,
      pauseOnHover: false,
    }
  }

  componentDidMount() {
    toast.dismiss();
    toast.configure(this.opts);
    this.setState({ loaded: true });
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()

    const _self = this;
    const button = document.querySelector('.retrieve-password-form');
    button.classList.add('disabled');
    button.disabled = true;

    toast.dismiss();
    const loading = toast.loading("Recherche en cours...");
    const response = await checkEmail(this.state);
    
    if (response.error) {
      setTimeout(() => {
        toast.dismiss();
        toast(response.error, { type: 'error', autoClose: false });
        button.classList.remove('disabled');
        button.disabled = false;
      }, 1000);
      return false;
    }
    
    axios.post(`${process.env.AFC_FUNCTIONS_ENDPOINT}/passwordRequest`, {
      name: response.name,
      email: response.email,
      link: `${process.env.AFC_URL}/app/nouveau-mot-de-passe/${response.id}/${response.token}`
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(res => {
      toast.update(loading, { 
        render: "Un email vous a été envoyé", 
        type: "success", 
        isLoading: false,
        ..._self.opts
      });
      button.classList.remove('disabled');
      button.disabled = false;
    })
  }

  render() {
    return (
      <MainContainer className={this.state.loaded ? 'loaded' : ''}>
        <Baseline>
          <View>
            <h2>Mot de passe oublié</h2>
            <nav id="breadcrumb">
              <ul>
                <li><Link to="/annonces">Accueil</Link></li>
                <li>
                  <Link to={`/app/mot-de-passe-oublie`}>Mot de passe oublié</Link>
                </li>
              </ul>
            </nav>
          </View>
        </Baseline>

        <View>
          <Row>
            <div className="col-xl-5 centered">
              <LostPassword
                handleUpdate={e => this.handleUpdate(e)}
                handleSubmit={e => this.handleSubmit(e)}
              />
            </div>
          </Row>
        </View>
      </MainContainer>
    )
  }
}

export default RetrievePassword