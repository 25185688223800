import React from "react"
import { Form } from '../../containers/MainWrapper'
import EmailIcon from '../../assets/icons/envelope.svg';

export default ({ handleSubmit, handleUpdate }) => (
  <Form>
    <div className="summary">
      <h3>Créer un nouveau mot de passe</h3>
      <span>
        Veuillez saisir votre adresse email ci-dessous afin de recevoir un lien de création de mot de passe.
      </span>
    </div>
    <form method="post" onSubmit={event => { handleSubmit(event) }}>

      <div className="input-text input-user-email">
        <span><EmailIcon/></span>
        <input 
          type="email" 
          name="email" 
          onChange={handleUpdate}
          placeholder="Adresse email"
        />
      </div>

      <button className="submit-form retrieve-password-form">Envoyer le lien</button>
    </form>
  </Form>
)